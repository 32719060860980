import { Datagrid, List, ShowButton, TextField, TextInput } from "react-admin";

const guestExecutionsFilters = [
  <TextInput label="Id" source="id" defaultValue="" />,
  <TextInput label="Workout Title" source="title" defaultValue="" />,
];

export const GuestExecutionsList = () => (
  <List filters={guestExecutionsFilters}>
    <Datagrid rowClick="show">
      <TextField source="id" label="Guest ID" />
      <TextField source="workoutTitle" label="Workout Title (Latest)" />
      <TextField source="duration" label="Duration (Latest)" />
      <TextField source="lastExecutionDateTime" label="Last Execution Time" />
      <TextField source="location" label="Location (Latest)" />
      <ShowButton />
    </Datagrid>
  </List>
);
