import { useGetOne, useRedirect, Title, Loading, List, Datagrid, ListActions, TextInput } from 'react-admin';
import { Show, SimpleShowLayout, TextField, DateField, RichTextField } from 'react-admin';

const globalChallengeFilters = [
    <TextInput label="Challenge Name" source="name" defaultValue=""/>,
    <TextInput label="Creator Id" source="creatorId" defaultValue=""/>,
    <TextInput label="Creator Name" source="creatorName" defaultValue=""/>,
];

export const GlobalChallengeList = () => (
    <List filters={globalChallengeFilters}>
        <Datagrid rowClick="show">
            <TextField source="id"/>
            <TextField label="Challenge Name" source="name"/>
            <TextField label="Members Count" source="membersCount"/>
            <TextField label="Workouts Count" source="workoutsCount"/>                
            <TextField label="Creator Id" source="creatorId"/>                
            <TextField label="Creator Name" source="creatorName"/>                
            <TextField label="Created Date" source="createdDateTime"/>                
        </Datagrid>
    </List>
);

