import React, { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { MenuItem, Select, InputLabel, FormControl, SelectChangeEvent, TextField, Button } from "@mui/material";

interface Community {
  id: string;
  name: string;
}

interface Challenge {
  id: string;
  name: string;
}

export const OfficialCommunityList = () => {
  const dataProvider = useDataProvider();
  const [communities, setCommunities] = useState<Community[]>([]);
  const [selectedCommunity, setSelectedCommunity] = useState<string>("");
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [challengeSchema, setChallengeSchema] = useState<any>(null);
  const [selectedChallenge, setSelectedChallenge] = useState<string>("");
  const [editableSchema, setEditableSchema] = useState<any>(null);

  const editableFields = [
    "workouts",
    "challengeType",
    "endDate",
    "startDate",
    "daysLimit",
    "description",
    "title",
    "communityId",
    "isShareable",
  ];

  useEffect(() => {
    dataProvider
      .getList("officialCommunity", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => setCommunities(data))
      .catch((error) => console.error("Error fetching communities:", error));
  }, [dataProvider]);

  useEffect(() => {
    if (selectedCommunity) {
      dataProvider
        .getList(`communityChallenges/${selectedCommunity}`, {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "id", order: "ASC" },
          filter: {},
        })
        .then(({ data }) => setChallenges(data))
        .catch((error) => console.error("Error fetching challenges:", error));
    }
  }, [selectedCommunity]);

  useEffect(() => {
    if (selectedChallenge) {
      dataProvider
        .getOne(`schema/globalChallenge`, { id: selectedChallenge })
        .then(({ data }) => {
          setChallengeSchema(data);
          setEditableSchema(data);
        })
        .catch((error) => console.error("Error fetching challenge schema:", error));
    }
  }, [selectedChallenge]);

  const handleCommunityChange = (event: SelectChangeEvent<string>) => {
    setSelectedCommunity(event.target.value);
    setSelectedChallenge("");
  };

  const handleChallengeChange = (event: SelectChangeEvent<string>) => {
    setSelectedChallenge(event.target.value);
  };

  const handleSchemaChange = (path: string[], value: any) => {
    setEditableSchema((prevSchema: any) => {
      const updatedSchema = { ...prevSchema };
      let nested = updatedSchema;
      path.slice(0, -1).forEach((key) => (nested = nested[key]));
      nested[path[path.length - 1]] = value;
      return updatedSchema;
    });
  };

  const renderEditableFields = (schema: any, path: string[] = []) => {
    return Object.keys(schema).map((key) => {
      const currentPath = [...path, key];
      const value = schema[key];

      const isEditable = editableFields.includes(key);

      if (typeof value === "object" && value !== null) {
        return (
          <div key={key} style={{ marginLeft: "1rem", borderLeft: "1px solid #ccc", paddingLeft: "1rem" }}>
            <strong>{key}:</strong>
            <div>{renderEditableFields(value, currentPath)}</div>
          </div>
        );
      } else if (isEditable) {
        return (
          <TextField
            key={key}
            label={key}
            value={value}
            onChange={(e) => handleSchemaChange(currentPath, e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        );
      } else {
        return (
          <div key={key} style={{ margin: "8px 0" }}>
            <strong>{key}:</strong> {String(value)}
          </div>
        );
      }
    });
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(editableSchema, null, 2));
    alert("Data copied to clipboard!");
  };

  const handleSubmitNewChallenge = async () => {
    try {
      await dataProvider.create(`add/globalChallenge`, { data: editableSchema });
      alert("New challenge created successfully!");
    } catch (error) {
      console.error("Error submitting new challenge:", error);
    }
  };

  return (
    <div>
      <FormControl fullWidth variant="outlined" style={{ marginTop: "2rem"}}>
        <InputLabel>Official Communities</InputLabel>
        <Select value={selectedCommunity} onChange={handleCommunityChange} label="Official Communities" displayEmpty>
          <MenuItem value="" disabled>
            Official Communities
          </MenuItem>
          {communities.map((community) => (
            <MenuItem key={community.id} value={community.id}>
              {community.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedCommunity && (
        <FormControl fullWidth variant="outlined" style={{ marginTop: "2rem" }}>
          <InputLabel shrink={!!selectedChallenge || challenges.length > 0}>Challenges</InputLabel>
          <Select value={selectedChallenge} onChange={handleChallengeChange} label="Challenges" displayEmpty>
            <MenuItem value="" disabled>
              Select a Challenge
            </MenuItem>
            {challenges.map((challenge) => (
              <MenuItem key={challenge.id} value={challenge.id}>
                {challenge.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <div style={{marginTop:40, marginBottom:40}}>
        <h2>Challenge Schema Data</h2>
        {editableSchema ? (
          <>
            {renderEditableFields(editableSchema)}
            <Button onClick={handleCopyToClipboard} variant="contained" color="primary" style={{ marginRight: "1rem" }}>
              Copy JSON Data
            </Button>
            <Button onClick={handleSubmitNewChallenge} variant="contained" color="secondary">
               Make it as a Global Challenge
            </Button>
          </>
        ) : (
          <p>No data available. Select a challenge to view its schema.</p>
        )}
      </div>
    </div>
  );
};
