import { useGetOne, useRedirect, Title, Loading, List, Datagrid, ListActions, TextInput } from 'react-admin';
import { Show, SimpleShowLayout, TextField, DateField, RichTextField } from 'react-admin';

const communityFilters = [
    <TextInput label="Community Name" source="communityName" defaultValue=""/>,
    <TextInput label="Creator Id" source="creatorId" defaultValue=""/>,
    <TextInput label="Creator Name" source="creatorName" defaultValue=""/>,
];

export const CommunityList = () => (
    <List filters={communityFilters}>
        <Datagrid rowClick="show">
            <TextField source="id"/>
            <TextField label="Community Name" source="communityName"/>
            <TextField label="Members Count" source="membersCount"/>
            <TextField label="Workouts Count" source="bookmarkedWorkoutsCount"/>                
            <TextField label="Creator Id" source="creatorId"/>                
            <TextField label="Creator Name" source="creatorName"/>                
            <TextField label="Created Date" source="createdDateTime"/>                
        </Datagrid>
    </List>
);

